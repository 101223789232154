import request from "../utils/request";

// 获取个人信息
export function getInfo(data) {
  return request({
    url: "user_details",
    method: "get",
    data: data,
  });
}
// 问题反馈
export function post_problem(data) {
  return request({
    url: "post_problem",
    method: "post",
    data: data,
  });
}
//修改密码
export function change_password(data) {
  return request({
    url: "change_password",
    method: "post",
    data: data,
  });
}
//修改用户信息
export function update_user(data) {
  return request({
    url: "update_user",
    method: "post",
    data: data,
  });
}
