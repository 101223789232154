<template>
  <div
    class="home"
    v-loading="loading"
    element-loading-text="等待加载（预计20秒）"
    element-loading-background="rgba(0, 0, 0, 0.2)"
  >
    <div class="Login-header">
      <div class="wrap">
        <div class="logo">
          <span>Recom Letter</span>
        </div>
        <div class="nav">
          <div
            class="item"
            :style="
              productServiceTitleState == 0 ? 'color:#333;font-weight:600;' : ''
            "
            @click="Jump('/')"
          >
            首页
            <div
              class="title-content-underLine"
              :style="
                productServiceTitleState == 0
                  ? 'transform: scaleX(1);color:#;'
                  : ''
              "
            ></div>
          </div>
          <div
            v-if="IsLogin"
            class="item"
            :style="
              productServiceTitleState == 5 ? 'color:#333;font-weight:600;' : ''
            "
            @click="Jump('/progres')"
          >
            进度查询
            <div
              class="title-content-underLine"
              :style="
                productServiceTitleState == 5 ? 'transform: scaleX(1);' : ''
              "
            ></div>
          </div>
          <div
            v-if="IsLogin"
            class="item"
            :style="
              productServiceTitleState == 1 ? 'color:#333;font-weight:600;' : ''
            "
            @click="Jump('/personal')"
          >
            推荐信下载
            <div
              class="title-content-underLine"
              :style="
                productServiceTitleState == 1 ? 'transform: scaleX(1);' : ''
              "
            ></div>
          </div>
          <div
            v-if="IsLogin"
            class="item"
            :style="
              productServiceTitleState == 2 ? 'color:#333;font-weight:600;' : ''
            "
            @click="Jump('/FormSubmit')"
          >
            表单提交
            <div
              class="title-content-underLine"
              :style="
                productServiceTitleState == 2 ? 'transform: scaleX(1);' : ''
              "
            ></div>
          </div>
          <div
            v-if="IsLogin && IsAdministrators"
            class="item"
            :style="
              productServiceTitleState == 3 ? 'color:#333;font-weight:600;' : ''
            "
            @click="Jump('/Administration')"
          >
            管理中心
            <div
              class="title-content-underLine"
              :style="
                productServiceTitleState == 3 ? 'transform: scaleX(1);' : ''
              "
            ></div>
          </div>
        </div>
        <div class="login" v-if="!IsLogin">
          <div
            class="home-button"
            @click="Jump('/login')"
            v-if="iSLoginPage != 1"
          >
            登录
          </div>
          <div
            class="home-button"
            @click="Jump('/Register')"
            v-if="iSLoginPage != 2"
          >
            注册
          </div>
        </div>
        <div
          class="login"
          style="display: flex; text-align: center"
          v-if="IsLogin"
        >
          <div
            v-if="IsLogin"
            class="item"
            :style="
              productServiceTitleState == 7 ? 'color:#333;font-weight:600;' : ''
            "
            @click="Jump('/PersonalCenter')"
          >
            个人中心
            <div
              class="title-content-underLine"
              :style="
                productServiceTitleState == 7 ? 'transform: scaleX(1);' : ''
              "
            ></div>
          </div>
          <div class="item" @click="ProblemFeedback">
            问题反馈
            <div class="title-content-underLine"></div>
          </div>

          <div
            class="item"
            :style="
              productServiceTitleState == 4 ? 'color:#333;font-weight:600;' : ''
            "
            @click="FedLogOut"
          >
            退出登录
            <div
              class="title-content-underLine"
              :style="
                productServiceTitleState == 4 ? 'transform: scaleX(1);' : ''
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
    <app-main class="Main" />
    <div class="footer">
      <div style="width: 300px; margin: 0 auto; padding: 10px 0">
        ICP证:
        <a href="https://beian.miit.gov.cn/" target="_blank">
          蜀ICP备2021022597号
        </a>
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002004358"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
        >
          <img
            src="../assets/imgs/9971da80bcc741a5c615f8c1837036d.png"
            style="float: left"
          />
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
          >
            川公网安备 51019002004358号
          </p></a
        >
      </div>
    </div>

    <el-dialog
      title="问题反馈"
      :visible.sync="ProblemFeedbackState"
      width="60%"
    >
      <el-row>
        <el-form :model="ProblemFeedbackForm" label-width="80px">
          <el-col>
            <el-form-item label="反馈类型">
              <el-select
                v-model="ProblemFeedbackForm.problem_name"
                placeholder="请选择反馈类型"
              >
                <el-option
                  v-for="item in ProblemFeedbackList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="反馈内容">
              <el-input
                type="textarea"
                maxlength="200"
                :autosize="{ minRows: 5, maxRows: 10 }"
                placeholder="请输入内容"
                show-word-limit
                v-model="ProblemFeedbackForm.problem"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { AppMain } from "../components";
import { getToken } from "../utils/auth";
import { getInfo } from "../api/user.js";
import store from "@/store";
import { post_problem } from "../api/user.js";
export default {
  name: "Layout",
  components: {
    AppMain,
  },
  data() {
    return {
      loading: null, //加载动画
      productServiceTitleState: null,
      iSLoginPage: 0, // 是否为登录或者注册页面  0 不是（登录、注册）页面， 1 登录页面  ， 2 注册页面,
      IsLogin: false,
      IsAdministrators: false,
      ProblemFeedbackList: ["功能建议", "BUG", "其他", "点赞"],
      ProblemFeedbackState: false,
      ProblemFeedbackForm: {},
    };
  },
  created() {
    // 默认判断初始页面是否为注册登录页面
    this.OnClickIsLoginPage(this.$route.path);
    // 默认判断选择样式
    this.ChangeState(this.$route.path);
    // 获取权限
    if (getToken()) {
      getInfo().then((res) => {
        // 从新获取权限判断入口权限存放到VueX
        // 从新获判断当前用户是否为管理员状态存放到VueX
        // 判断是否为老师
        var EntryPermissions = [];

        if (res.data.permission_info[0] != 0) {
          if (res.data.permission_info[0] == 99) {
            this.$store.commit("SET_Login", true);
            this.$store.commit("SET_Administrators", true);
            this.$store.commit("SET_EntryPermissions", [1, 2, 3, 4, 5, 6]); //超级管理员附有所有入口权限
          } else {
            this.$store.commit("SET_Administrators", true);
            this.$store.commit("SET_Login", true);
            if(res.data.is_teacher == 1){
                EntryPermissions.push(3);
                EntryPermissions.push(4);
                EntryPermissions.push(5);
                EntryPermissions.push(6);
            }
            if (res.data.permission_info.includes("查看用户")) {
              EntryPermissions.push(2);
            }
            if (res.data.permission_info.includes("查看模板")) {
              EntryPermissions.push(3);
            }
            if (res.data.permission_info.includes("查看表单")) {
              EntryPermissions.push(6);
            }
            if (res.data.permission_info.includes("审核推荐信")) {
              EntryPermissions.push(5);
            }
            if (
              res.data.permission_info.includes("生成推荐信") ||
              res.data.permission_info.includes("查看推荐信")
            ) {
              EntryPermissions.push(4);
            }
            this.$store.commit(
              "SET_EntryPermissions",
              ...new Set(EntryPermissions)
            );
          }
        } else if (res.data.is_teacher == 1) {
          EntryPermissions.push(3);
          EntryPermissions.push(4);
          EntryPermissions.push(5);
          EntryPermissions.push(6);
          this.$store.commit(
            "SET_EntryPermissions",
            ...new Set(EntryPermissions)
          );
          this.$store.commit("SET_Administrators", true);
          this.$store.commit("SET_Login", true);
        } else {
          this.$store.commit("SET_Administrators", false);
          this.$store.commit("SET_Login", true);
        }
      });
    }
  },
  mounted() {
    this.loading = this.$store.state.app.loading;
  },
  watch: {
    $route(route) {
      this.OnClickIsLoginPage(route.path);
      this.ChangeState(route.path);
    },
    "$store.state.app.loading"(val) {
      this.loading = val;
    },
    "$store.state.user.IsLogin"(val) {
      this.IsLogin = val;
    },
    "$store.state.user.IsAdministrators"(val) {
      this.IsAdministrators = val;
    },
  },
  methods: {
    //   问题反馈
    ProblemFeedback() {
      this.ProblemFeedbackState = true;
      this.ProblemFeedbackForm = {};
    },
    // 取消问题反馈
    cancel() {
      this.ProblemFeedbackState = false;
      this.ProblemFeedbackForm = {};
    },
    // 确认问题反馈
    confirm() {
      post_problem({
        question: this.ProblemFeedbackForm,
      }).then((res) => {
        if (res.code == 200) {
          this.cancel();
          this.$message({
            message: res.msg,
            type: "success",
          });
        }
      });
    },
    // 切换加载动画效果
    LodingData(state) {
      this.loading = state;
    },
    // 选中样式判断
    ChangeState(Url) {
      switch (Url) {
        case "/":
          this.productServiceTitleState = 0;
          break;
        case "/progres":
          this.productServiceTitleState = 5;
          break;
        case "/personal":
          this.productServiceTitleState = 1;
          break;
        case "/FormSubmit":
          this.productServiceTitleState = 2;
          break;
        case "/Administration":
          this.productServiceTitleState = 3;
          break;
        case "/User":
          this.productServiceTitleState = 4;
          break;
        case "/ProblemFeedback":
          this.productServiceTitleState = 6;
          break;
        case "/PersonalCenter":
          this.productServiceTitleState = 7;
          break;
        default:
          this.productServiceTitleState = null;
          return;
      }
    },
    // 判断是否为登录页面或者注册页面
    OnClickIsLoginPage(Url) {
      if (Url == "/login") {
        this.iSLoginPage = 1;
      } else if (Url == "/Register") {
        this.iSLoginPage = 2;
      } else {
        this.iSLoginPage = 0;
      }
    },
    // 路由跳转
    Jump(URl) {
      this.$router.push(URl);
    },
    // 退出登录
    FedLogOut() {
      this.$confirm("此操作将退出登录, 是否继续?", "提示", {
        confirmButtonText: "退出",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          store.dispatch("FedLogOut").then(() => {
            this.$router.push({
              path: this.redirect || "/login",
            });
            sessionStorage.clear();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  overflow: hidden;
  .Login-header {
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    line-height: 80px;
    align-items: center;
    font-size: 8px;
    font-family: auto;
    .wrap {
      box-sizing: border-box;
      display: flex;
      width: 80%;
      height: 80px;
      margin-left: auto;
      margin-right: auto;
      .logo {
        padding: 0 50px;
        font-size: 3em;
        font-weight: bold;
        color: #000;
        background-image: url("../assets/icon/logo.png");
        background-size: 38px 38px;
        background-repeat: no-repeat;
        background-position: left center;
      }
      .nav {
        display: flex;
        .item {
          width: 80px;
          margin: 0 10px;
          // padding-bottom: 10px;
          //   color: #000;
          color: #666;
          font-size: 2em;
          box-sizing: border-box;
          position: relative;
          cursor: pointer;
          text-align: center;
          .title-content-underLine {
            position: absolute;
            left: 0;
            bottom: 16px;
            transform: scaleX(0);
            transform-origin: 50% 50%;
            transition: transform 0.3s ease;
            width: 100%;
            // border-bottom: 3px solid #ff6a00;
            border-bottom: 3px solid #000;
          }
        }
        .item:hover .title-content-underLine {
          transform: scaleX(1);
        }
      }
      .login {
        margin-left: auto;
        line-height: 80px;
        .item {
          width: 80px;
          margin: 0 10px;
          // padding-bottom: 10px;
          color: #000;
          color: #666;
          font-size: 2em;
          box-sizing: border-box;
          position: relative;
          cursor: pointer;
          .title-content-underLine {
            position: absolute;
            left: 0;
            bottom: 16px;
            transform: scaleX(0);
            transform-origin: 50% 50%;
            transition: transform 0.3s ease;
            width: 100%;
            // border-bottom: 3px solid #ff6a00;
            border-bottom: 3px solid #000;
          }
        }
        .item:hover .title-content-underLine {
          transform: scaleX(1);
        }
        .home-button {
          display: inline-block;
          // height: 32px;
          line-height: 32px;
          text-align: center;
          color: #000;
          border: 1px solid #000;
          margin: 23px 10px;
          width: 90px;
          font-size: 2em;
          letter-spacing: 2px;
          position: relative;
          transition: all 0.3s;
          border-radius: 20px;
          cursor: pointer;
        }
        .home-button:after {
          content: "";
          position: absolute;
          z-index: -9;
          left: 0;
          top: 0;
          height: 100%;
          width: 0;
          background-color: #000;
          border-radius: 20px;
          overflow: hidden;
          transition: all 0.3s;
        }
        .home-button:hover {
          color: #fff;
          z-index: 10;
          font-weight: bold;
        }
        .home-button:hover:after {
          width: 100%;
        }
      }
      // .login:first-child .home-button{
      //     margin-right: 20px;
      // }
    }
  }
  .Main {
    // width: 100%;
    min-height: calc(100vh - 160px);
  }
  .footer {
    width: 100%;
    height: 80px;
    background-color: rgb(25, 35, 55);
    line-height: 30px;
    text-align: center;
    color: rgb(147, 147, 147);
  }
}
</style>
